@media print {
    @page {
        size: auto;   /* auto is the initial value */
        margin: 25px 15px 15px 15px;   /* this affects the margin in the printer settings */
    }


    /*    */


}

.printOrderContainer1 .topbar .topbarWrapper {
    padding-left: 0;
}

.printOrderContainer1{
    font-size: 0.9rem;
    padding: 20px;
}

.pageTitle{
    font-size: 18px;
    font-family: proxima-nova, sans-serif !important;
    color: #0d2959;
    font-weight: 600;
    text-transform: uppercase;
}

.textStyle{
    color: #0d2959;
}

.unorderedList{
    list-style: none;
    margin: 0 0 2em 0;
    color: #0d2959;
    display: flex;
    flex-wrap: wrap;
    padding-inline-start: 0;
}

.ulItem{
    color: #0d2959;
    flex: 1 0;
    float: left;
    margin-right: 2em;
    border-right: 1px dashed #d4ced2;
    padding-right: 2em;
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
}

.printHeading{
    color: #536889;
}


.listSpanItem{
    line-height: 1;
}

.print1Table1{
    border: 1px solid rgba(0,0,0,.1);
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
    border-collapse: separate;
    border-radius: 5px;
    color: #536889;
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
}

.print1Table2{
    border: 1px solid rgba(0,0,0,.1);
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
    border-collapse: separate;
    border-radius: 5px;
    color: #536889;
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
}

.printContainer1{
    padding: 20px;
}

.print1Table5{
    border: 1px solid rgba(0,0,0,.1);
    margin: 0 -1px 24px 0;
    text-align: left;
    border-collapse: separate;
    border-radius: 5px;
    color: #536889;
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
}

.print1Table25{
    border: 1px solid rgba(0,0,0,.1);
    margin: 0 -1px 24px 0;
    text-align: left;
    border-collapse: separate;
    border-radius: 5px;
    color: #536889;
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
}

.printContainer5{
    padding: 30px;
}

.wooPrintOrderDetails{
    margin-bottom: 2em;
    color: #536889;
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    margin-block-start: 1em;
    display: flex;
    flex-direction: column;
}

.printOrderBillingDetails{
    font-style: normal;
    margin-bottom: 0;
    border: 1px solid rgba(0,0,0,.1);
    border-bottom-width: 2px;
    border-right-width: 2px;
    text-align: left;
    border-radius: 5px;
    padding: 6px 12px;
    display: contents;
}
