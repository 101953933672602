.formContainer {
    display: flex;
    flex-direction: column;
}

.column {
    flex: 1;
    padding: 10px;
}

.row {
    display: flex;
    flex-direction: row;
    /*flex: 1;*/
}

.companyDetails{
    flex: 1;
}

.companyAddress{
    flex: 1;
    margin-left: 10px;
}

.companyColumns{
    display: flex;
    flex-direction: row;
    margin-left: 10px;
}

.addressBox {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
}

.addressBoxTitle {
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.beneficialOwnerName{
    display: flex;
    font-weight: 700;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
}

.companyColumns {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
}

.AddBOIButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    /*justify-content: space-between;*/
}

.icon {
    margin-right: 10px; /* Adjust this value as needed */
}

.buttonContainer{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
    .companyColumns {
        flex-direction: column;
    }
}

.tooltip-row[data-tooltip]:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    background-color: #f0f0f1;
    color: #0d2959;
    padding: 4px 8px;
    border-radius: 4px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1000;
    font-size: 0.9rem;
}

.tooltip-row {
    position: relative;
}

.tooltip-row[metadata-tooltip]:hover::after {
    content: attr(metadata-tooltip);
    position: absolute;
    background-color: white;
    color: #0d2959;
    padding: 4px 8px;
    border-radius: 4px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1000;
    font-size: 0.9rem;
}

.relatedSubscriptionsMetaHeader {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.relatedSubscriptionsMetaHeader li {
    flex: 1;
    text-align: left;
    padding: 5px;
}

ul{
    margin-block-start: 0.4em !important;
    margin-block-end: 0.2em !important;
}

