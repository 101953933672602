.addPaymentMethod{
    font-size: 100%;
    margin-left: 20px;
    line-height: 1;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    overflow: visible;
    padding: 0.618em 1em;
    font-weight: 700;
    border-radius: 3px;
    left: auto;
    color: #0d2959;
    background-color: #f6bb46;
    border: 0;
    display: inline-block;
    background-image: none;
    box-shadow: none;
    text-shadow: none;
}

.addPaymentMethod:hover{
    color: #ebe9eb;
    background-color: #0d2959;
}

/*.actionButtons{*/
/*    display: inline-flex;*/
/*}*/

/*.subIds{*/
/*    display: inline-flex;*/
/*}*/

/*.subIds:hover{*/
/*    color: #f6bb46;*/
/*}*/

/*.loading {*/
/*    font-weight: bold;*/
/*    display:inline-block;*/
/*    font-family: monospace;*/
/*    font-size: 30px;*/
/*    clip-path: inset(0 3ch 0 0);*/
/*    animation: l 1s steps(4) infinite;*/
/*}*/

/*@keyframes l {*/
/*    to {*/
/*        clip-path: inset(0 -1ch 0 0)*/
/*    }*/
/*}*/

/*.confirmSelect{*/
/*    align-content: center;*/
/*    padding-left: 10px;*/
/*}*/

/*.l4sbBtn{*/
/*    font-size: 100%;*/
/*    margin-right: 5px;*/
/*    line-height: 1;*/
/*    cursor: pointer;*/
/*    position: relative;*/
/*    text-decoration: none;*/
/*    overflow: visible;*/
/*    padding: 0.618em 1em;*/
/*    font-weight: 700;*/
/*    border-radius: 3px;*/
/*    left: auto;*/
/*    border: 0;*/
/*    display: inline-block;*/
/*    background-image: none;*/
/*    box-shadow: none;*/
/*    text-shadow: none;*/
/*    color: #0d2959 !important;*/
/*    background-color: #f6bb46 !important;*/
/*}*/

/*.l4sbBtn:hover{*/
/*    color: #ebe9eb !important;*/
/*    background-color: #0d2959 !important;*/
/*}*/

/*.custom-ok-button {*/
/*    background-color: #4CAF50 !important;*/
/*    color: red !important;*/
/*    border-radius: 4px;*/
/*    padding: 8px 12px;*/
/*    cursor: pointer;*/
/*    font-size: 16px;*/
/*}*/

/*.rrt-ok-btn{*/
/*    font-size: 100%;*/
/*    margin: 5px !important;*/
/*    line-height: 1;*/
/*    cursor: pointer;*/
/*    position: relative;*/
/*    text-decoration: none;*/
/*    overflow: visible;*/
/*    padding: 0.618em 1em;*/
/*    font-weight: 700;*/
/*    border-radius: 3px;*/
/*    left: auto;*/
/*    border: 0;*/
/*    display: inline-block;*/
/*    background-image: none;*/
/*    box-shadow: none;*/
/*    text-shadow: none;*/
/*    color: #0d2959 !important;*/
/*    background-color: #f6bb46 !important;*/
/*}*/

/*.rrt-cancel-btn{*/
/*    font-size: 100%;*/
/*    margin: 5px !important;*/
/*    line-height: 1;*/
/*    cursor: pointer;*/
/*    position: relative;*/
/*    text-decoration: none;*/
/*    overflow: visible;*/
/*    padding: 0.618em 1em;*/
/*    font-weight: 700;*/
/*    border-radius: 3px;*/
/*    left: auto;*/
/*    border: 0;*/
/*    display: inline-block;*/
/*    background-image: none;*/
/*    box-shadow: none;*/
/*    text-shadow: none;*/
/*    color: #0d2959 !important;*/
/*    background-color: #f6bb46 !important;*/
/*}*/

/*.rrt-cancel-btn:hover{*/
/*    color: #ebe9eb !important;*/
/*    background-color: #0d2959 !important;*/
/*}*/

/*.rrt-ok-btn:hover{*/
/*    color: #ebe9eb !important;*/
/*    background-color: #0d2959 !important;*/
/*}*/

/*.rrt-confirm{*/
/*    word-break: break-word !important;*/
/*}*/