/*.faqContent{*/
/*    !*white-space: pre-line;*!*/
/*    line-height: inherit;*/
/*}*/

/*.faqTitle{*/
/*    font-family: "proxima-nova",sans-serif !important;*/
/*    color: #0d2959;*/
/*    font-weight: 600;*/
/*    text-transform: uppercase;*/
/*}*/

/*.helpPageWrapper{*/
/*    flex: 1;*/
/*    height: 60%;*/
/*    margin-left: 20px;*/
/*    color: #536889;*/
/*    font-size: 1.2rem;*/
/*    margin-right: 20px;*/
/*    max-width: 1000px;*/
/*}*/

/*.displayHelpData{*/

/*}*/