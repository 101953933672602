/* Default styles for larger screens */
.topbar {
    /* your existing styles */
}

.topbarWrapper {
    /* your existing styles */
}

.topbarLeft {
    /* your existing styles */
}

.topbarRight {
    /* your existing styles */
}

/* Responsive styles for screens smaller than 768px */
@media screen and (max-width: 768px) {
    .topbar {
        width: 100%;
        height: auto;
        background-color: white;
        padding: 7px 0;
        font-family: "proxima-nova", sans-serif;
    }

    .topbarWrapper {
        height: 100%;
        padding: 20px 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .topbarLeft, .topbarRight {
        width: 100%; /* full width */
        text-align: center; /* center content */
        margin-bottom: 10px; /* add some space between */
    }

    .topbarLeft img {
        width: 120px; /* smaller logo */
        height: auto; /* maintain aspect ratio */
    }

    .myInMyAccount, .accountInMyAccount {
        font-size: 14px; /* smaller font size */
    }

    /* Adjust the size and layout of the avatar in the top right */
    .settingsAvatar > .MuiAvatar-root, .MuiAvatar-root {
        width: 50px; /* smaller avatar */
        height: 50px;
        font-size: 1.5rem; /* smaller font size for initials */
    }
}




/*.navLinkStyle{*/
/*    text-decoration: none !important;*/
/*}*/

/*.logoutListItem{*/
/*    cursor: pointer;*/
/*    position: relative;*/
/*    top: -6px;*/
/*    left: 8px;*/
/*}*/

/*.sidebarHolder{*/
/*    height: max-content;*/
/*    background-color: #f0f0f0;*/
/*    position: relative;*/
/*    display: flex !important;*/
/*    padding-top: 50px;*/
/*    margin: 0 20px 20px 0;*/
/*    padding-left: 10px;*/
/*    padding-right: 10px;*/
/*}*/

/*.toggleSidebar{*/
/*    position: absolute;*/
/*    right: -18px;*/
/*    top: 20px;*/
/*    border: 0.1em outset #536889;*/
/*}*/

/*button.toggleSidebar{*/
/*    padding: 1px 6px;*/
/*    border: 2px solid #536889;*/
/*    justify-content: center;*/
/*    display: flex;*/
/*}*/

/*.sidebar{*/
/*    height: max-content;*/
/*    background-color: #f0f0f0;*/
/*    position: relative;*/
/*    padding-left: 10px;*/
/*    padding-right: 10px;*/
/*    margin: 0 20px 20px 0;*/
/*    display: flex !important;*/
/*    flex-direction: column;*/
/*}*/

/*.sidebarWrapper{*/
/*    padding: 20px 20px 0 20px;*/
/*    color: #555;*/
/*}*/

/*.sidebarMenu{*/
/*    margin-bottom: 10px;*/
/*}*/

/*.sidebarTitle{*/
/*    color: #0d2959;*/
/*    letter-spacing: 2px;*/
/*    text-transform: uppercase;*/
/*    font-size: 1rem !important;*/
/*    font-weight: 600;*/
/*    line-height: 2.5;*/
/*}*/

/*.sidebarList{*/
/*    list-style: none;*/
/*    padding: 5px;*/
/*}*/

/*.sidebarListItem{*/
/*    padding-left: 10px;*/
/*    padding-right: 10px;*/
/*    align-items: center;*/
/*    border-radius: 10px;*/
/*    color: #0d2959;*/
/*    letter-spacing: 2px;*/
/*    text-transform: uppercase;*/
/*    font-size: 14px !important;*/
/*    font-weight: 600;*/
/*    line-height: 2.5;*/
/*    position: relative;*/
/*}*/

/*.sidebarIcon{*/
/*    margin-right: 5px;*/
/*    font-size: 2rem !important;*/
/*}*/

/*.toggleSidebar:hover{*/
/*    color: #f6bb46;*/
/*    cursor: pointer;*/
/*}*/

/*.versionNumber{*/
/*    text-align: right;*/
/*}*/
