/*.loading {*/
/*    font-weight: bold;*/
/*    display:inline-block;*/
/*    font-family: monospace;*/
/*    font-size: 30px;*/
/*    clip-path: inset(0 3ch 0 0);*/
/*    animation: l 1s steps(4) infinite;*/
/*}*/

/*@keyframes l {*/
/*    to {*/
/*        clip-path: inset(0 -1ch 0 0)*/
/*    }*/
/*}*/

/*.actions{*/
/*    cursor: pointer;*/
/*}*/

/*.actionButtons{*/

/*}*/

.expandable-row {
    transition: all 0.4s ease-out; /* Controls expand speed */
    overflow: hidden;
}
.expandable-row.expand {
    max-height: 200px; /* Adjust based on your content */
    opacity: 1;
}
.expandable-row.collapse {
    max-height: 0px;
    opacity: 0;
}
