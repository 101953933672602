.custom-banner-container {
    background-color: #7d0029; /* Adjust as per your theme */
    color: #ffffff;
    padding: 7px 0;
    text-align: center;
    font-size: 15px;
    margin-bottom: 10px;
}

.custom-banner-content {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--grid-width-full);
    padding-left: var(--grid-gutter);
    padding-right: var(--grid-gutter);
    width: 100%;
}

.custom-banner-link {
    color: #f6bb46;
    text-decoration: none;
    font-weight: bold;
}

.custom-banner-link:hover {
    text-decoration: underline;
}
