.MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input {
    padding: 8px 8px 8px 8px !important;
}

.datePickerField {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.datePickerField .formikLabel {
    margin-bottom: 0;  /* Override the default margin-bottom */
    flex: 0 0 50%;    /* Allocate 50% width to the label */
}

.datePickerField .formikInput {
    flex: 1;  /* Fill the remaining space with the input */
}

.formFieldInput {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.formFieldCheckBoxInput{
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
}

.checkBoxContainer{
    margin-bottom: 10px;
}

.formikLabel, .checkBoxLabel, .selectLabel {
    display: flex;
    align-items: center;
    gap: 4px;
    /*margin-bottom: 5px;*/
    font-weight: bold;
    min-width: 50%;
}

.checkBoxLabel{
    display: block;
    /*margin-bottom: 5px;*/
    font-weight: bold;
    min-width: 50%;
    flex:1;
}

.formikInput, .formikTextArea, .selectInput {
    /*width: 100%;*/
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: large;
}

.formikTextArea {
    resize: vertical; /* Allows vertical resizing, you might want to allow or restrict this */
}

.formikInput-error {
    border-color: red;
}

.checkBoxInput {
    margin-right: 10%;
    width: 5%;
}

.error {
    color: red;
    margin-top: 5px;
    font-size: 0.85em;
    padding-left: 5px;
}

.requiredAsterisk {
    color: red;
    font-size: inherit;
    vertical-align: middle;
    margin-bottom: 2px;
}

.companyColumns{
    flex-direction: column;
}

.readOnlyInput {
    background-color: #f3f3f3; /* Light gray background */
    color: #686868;           /* Darker text to increase readability */
    cursor: not-allowed;      /* Shows a 'no action' cursor when hovered */
}
/*.formikLabel{*/
/*    color: var(--color-brand-blue);*/
/*    display: block;*/
/*    font-size: var(--font-size-small);*/
/*    font-weight: var(--font-weight-semi-bold);*/
/*    letter-spacing: 2px;*/
/*    line-height: var(--line-height-body-desktop)!important;*/
/*    margin-bottom: 4px;*/
/*    text-transform: uppercase;*/
/*}*/

/*.formikInput{*/
/*    box-sizing: border-box;*/
/*    width: 100%;*/
/*    margin: 0;*/
/*    outline: 0;*/
/*    line-height: normal;*/
/*    padding: var(--spacing-xs);*/
/*}*/

/*.formButtonsDiv{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*}*/

/*.formikInput::-webkit-input-placeholder { !* Chrome/Opera/Safari *!*/
/*    color: #536889 !important;*/
/*}*/
/*.formikInput::-moz-placeholder { !* Firefox 19+ *!*/
/*    color: #536889 !important;*/
/*}*/
/*.formikInput:-ms-input-placeholder { !* IE 10+ *!*/
/*    color: #536889 !important;*/
/*}*/
/*.formikInput:-moz-placeholder { !* Firefox 18- *!*/
/*    color: #536889 !important;*/
/*}*/

/*.select{*/
/*    width: 300px;*/
/*}*/
/*.formikInput-error {*/
/*    border: 2px solid red !important;*/
/*}*/

/*.error {*/
/*    color: red;*/
/*    font-size: 0.8em;*/
/*    margin-top: 5px;*/
/*}*/

/*.formikFullName{*/

/*}*/

/*.editBillingAddressForm{*/
/*    padding-bottom: 400px;*/
/*}*/